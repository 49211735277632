html {
    font-size: 15px;
}

body {
    font-family: var(--ds-ci-font);
    background-color: var(--ds-color-n5);
}

.fw-bold {
    font-weight: 600 !important;
}

.ds-font-loader:not(.ds-spinner) {
    font-family: var(--ds-ci-font-loader);
}

.ds-font-loader .ds-card {
    opacity: 0.4;
}

.ds-font-loader h4.mb-0 {
    color: var(--ds-color-n4);
    padding-left: 0.5rem;
}

.ds-bg-secondary {
    background-color: var(--ds-color-secondary);
    color: var(--ds-color-white);
}

.ds-bg-section {
    background-color: var(--ds-color-section-hg);
    padding-bottom: 0.1rem;
}

.modal-body .ds-bg-section {
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 2rem;
}

.navbar {
    padding-top: 1rem;
}

.navbar img {
    height: 2.3rem;
}

h4.ds-company-name {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--ds-color-primary);
    padding-top: 0.8rem;
}

nav > div > .row,
header > .row,
section > .row {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.form-control,
.form-select,
.input-group-text {
    color: var(--ds-color-black);
    background-color: var(--ds-color-n6);
    border: 1px solid var(--ds-color-n5);
}

.form-control:focus,
.form-check-input:focus,
.form-select:focus {
    background-color: var(--ds-color-n6);
    border: 1px solid var(--ds-color-n5);
    box-shadow: 0 0 0 0.25rem rgba(105, 112, 119, 0.25);
}

.form-control:disabled {
    background-color: var(--ds-color-n6);
    color: var(--ds-color-n4);
}

.ds-icon-modal .ds-card-icon {
    box-shadow: 0 0rem 0.6rem rgba(0, 0, 0, 0.5);
}

.input-group-text i {
    color: var(--ds-color-n3);
}

.ds-card-icon.ds-card-icon-qr-scan {
    background-color: var(--ds-color-secondary);
    color: var(--ds-color-n1);
}

.ds-card-icon.ds-card-icon-qr-scan i {
    color: var(--ds-color-primary);
}

.ds-card-icon-qr-scan-border {
    border: 1px dashed var(--ds-color-primary);
    padding: 0.6rem 0.7rem;
    border-radius: 1.3rem;
}

.ds-card-qr-scan {
    color: var(--ds-color-n1);
}

.ds-icon-form input {
    padding-left: 2rem;
}

.ds-btn-group,
.ds-card {
    padding: 0.35rem;
    background-color: var(--ds-color-white);
    border-radius: var(--ds-border-radius-normal);
    border: 1px solid var(--ds-color-n6);
    box-shadow: var(--ds-box-shadow);
}

.ds-card {
    padding: 0.2rem;
    margin-bottom: 1rem;
}

.ds-card:hover
{
    box-shadow: 0 .1rem 1rem rgba(0,70,80,0.3);
}

.ds-card-visit .ds-card-icon {
    background-color: var(--ds-color-n5);
}

.modal-body .ds-card {
    padding: var(--bs-modal-padding);
}

.modal-body .ds-card:not(.ds-card-alert) {
    background-color: var(--ds-color-section-hg);
}

.ds-card-alert .ds-ticket-alert {
    font-size: 2.3rem;
}

section .ds-card:last-child {
    margin-bottom: 0;
}

.ds-btn-group > button.btn {
    font-weight: bolder;
    color: var(--ds-color-n4);
    padding-left: 1.1rem;
    padding-right: 1.1rem;
}

.ds-btn-group > button.btn:hover {
    color: var(--ds-color-n1);
}

.ds-btn-group > button.btn:focus,
.ds-btn-group > button.btn:active,
.ds-btn-group > button.btn:checked,
.ds-btn-group .btn.show {
    border: 1px solid transparent;
}

.ds-btn-group > .btn.active {
    background-color: var(--ds-color-special);
    border: 0;
    border-radius: 1.5em;
    border-top-right-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
    color: var(--ds-color-n1);
}

.ds-dropdown-headline {
    font-weight: 600;
    background-color: transparent;
}

.dropdown-item:focus,
.ds-dropdown-headline.dropdown-item:hover {
    background-color: transparent;
}

.ds-btn-group > button:not(:last-child).btn.active {
    margin-right: 1rem;
}

.btn-group.ds-btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
}

.ds-btn-group-center {
    margin-right: -3.35rem;
}

@media (max-width: 400px) {
    .ds-btn-group-center {
        margin-left: 0;
        margin-right: -2.9rem;
    }

    .ds-btn-group-center > button.btn {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

.btn-secondary {
    background-color: var(--ds-color-secondary);
    border-color: var(--ds-color-secondary);
    border-radius: var(--ds-border-radius-normal);
}

.btn-secondary:hover {
    background-color: var(--ds-color-secondary-hover);
    border-color: var(--ds-color-secondary-hover);
}

.btn-secondary:active {
    background-color: var(--ds-color-secondary-active);
    border-color: var(--ds-color-secondary-active);
}

.btn-primary {
    background-color: var(--ds-color-special);
    border-color: var(--ds-color-special);
    border-radius: var(--ds-border-radius-normal);
    color: var(--ds-color-n1);
}

.btn-primary:hover {
    background-color: var(--ds-color-special-hover);
    border-color: var(--ds-color-special-hover);
    color: var(--ds-color-n1);
}

.btn-primary:active,
.btn.btn-primary:active,
.btn.btn-primary:focus-visible {
    background-color: var(--ds-color-special-active);
    border-color: var(--ds-color-special-active);
    color: var(--ds-color-n1);
}

.btn.btn-primary:focus-visible {
    box-shadow: 0 0 0 0.25rem rgba(105, 112, 119, 0.25);
}

.btn-primary.btn:disabled {
    background-color: var(--ds-color-special-active);
    border-color: var(--ds-color-special-active);
    color: var(--ds-color-n1);
    opacity: 1;
}

.text-color-special {
    color: var(--ds-color-special);
}

.text-color-n4 {
    color: var(--ds-color-n4);
}

.text-color-n3 {
    color: var(--ds-color-n3);
}

.ds-card-icon {
    background-color: var(--ds-color-n4);
    border-radius: 1.3rem;
    /* height: calc(100% - 0.6rem); */
    height: 4em;
    aspect-ratio: 1 / 1;
    margin: 0.3rem;
}

.ds-card-icon i {
    color: var(--ds-color-white);
    font-size: 1.5rem;
}

.ds-padding-left-0-5 {
    padding-left: 0.5rem;
}

.ds-padding-left-1 {
    padding-left: 1rem;
}

.ds-link-ticket i {
    color: var(--ds-color-n3);
    font-size: 1.5rem;
    line-height: revert;
}

.ds-ticket-alert {
    color: var(--ds-color-danger);
    font-size: 1.5rem;
    line-height: revert;
}

.ds-color-danger {
    color: var(--ds-color-danger);
}

.ds-color-warning {
    color: var(--ds-color-warning);
}

.ds-card-room-name {
    margin-top: -0.2rem;
    display: block;
}

.tooltip-inner {
    background-color: var(--ds-color-n3);
}

.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-top-color: var(--ds-color-n3);
}

.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-left-color: var(--ds-color-n3);
}

.ds-dropdown-toggle > button.btn {
    padding-left: 1rem;
    padding-right: 1rem;
}

.ds-dropdown-toggle .dropdown-toggle::after {
    display: none;
}

.form-check-input {
    background-color: var(--ds-color-n4);
    border-color: var(--ds-color-n4);
}

.form-check-input:checked {
    background-color: var(--ds-color-secondary);
    border-color: var(--ds-color-secondary);
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255, 255, 255, 1)'/%3e%3c/svg%3e");
}

.form-check-input {
    border: 1px solid var(--ds-color-n4);
}

.form-switch .form-check-input {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255, 255, 255, 1)'/%3e%3c/svg%3e");
}

@media (max-width: 345px) {
    .ds-diagram,
    .ds-diagram-date {
        display: none;
    }
}

.ds-diagram {
    height: 17rem;
}

.ds-day-bar-container {
    height: calc(100% - 2.2rem);
    display: flex;
    flex-direction: column;
}

.ds-day-bar-numer {
    flex-grow: 1;
    align-self: stretch;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
}

.ds-day-bar {
    background-color: var(--ds-color-special);
    width: 1.5rem;
    height: calc(100% - 1rem);
    border-radius: 10rem;
    align-self: flex-end;
}

@media (min-width: 997px) {
    .ds-day-bar {
        width: 2rem;
    }
}

@media (max-width: 400px) {
    .ds-diagram {
        height: 10rem;
    }

    .ds-diagram,
    .ds-diagram-date.pb-4,
    .ds-diagram .mb-3 {
        padding-bottom: 0.8rem !important;
    }

    .ds-diagram-date.pt-4 {
        padding-top: 0.8rem !important;
    }

    .ds-diagram h5 {
        font-size: 1rem;
    }

    .ds-day-bar-container {
        height: calc(100% - 1rem);
    }
}

@media (orientation: landscape) and (max-width: 999px) {
    .ds-diagram {
        height: 7rem;
    }

    .ds-diagram h5 {
        font-size: 0.9rem;
    }

    .ds-diagram,
    .ds-diagram-date.pb-4,
    .ds-diagram .mb-3 {
        padding-bottom: 0.5rem !important;
    }

    .ds-diagram-date.pt-4 {
        padding-top: 0.5rem !important;
    }

    .ds-day-bar-container {
        height: calc(100% - 0.8rem);
    }
}

footer {
    padding: 1rem;
}

footer,
footer * {
    color: var(--ds-color-n4);
    text-decoration: none;
    font-size: 0.8rem;
}

.ds-mouse-pointer {
    cursor: pointer;
}

.modal-body {
    padding: 0 2rem 2rem 2rem;
}

.ds-modal .modal-dialog {
    padding-top: 0.5rem;
}

@media (max-width: 575px) {
    .ds-modal .modal-dialog {
        padding-top: 1.8rem;
    }
}

@media (min-width: 576px) {
    .ds-modal {
        margin-top: 2.5rem;
    }
}

.modal-header,
.modal-footer {
    border: 0;
}

.ds-icon-modal {
    margin-top: -3.2rem;
}

.ds-btn-hidden-function .dropdown-toggle::after {
    display: none;
}

.ds-btn-hidden-function .dropdown-menu {
    min-width: 17rem;
}

.ds-icon-modal-center {
    padding-left: 1.4rem;
}

.ds-modal-accounticon-icon {
    width: 2.3rem;
}

.form-label {
    margin-bottom: 0.2rem;
}

.ds-login-image {
    width: 25rem;
}

a.ds-login-help-link,
a.ds-login-help-link:hover {
    font-size: 0.9rem;
    color: var(--ds-color-white);
}

.ds-spacer {
    height: 0.8rem;
}

.ds-card-qr-scan a {
    color: var(--ds-color-n1);
    text-decoration: none;
}

.ds-code-scanner-border {
    border: 0.4rem solid var(--ds-color-primary);
    border-radius: 0.5rem;
    min-height: 30vh;
}

.ds-form-switch-ready {
    padding-left: 0;
}

.form-switch.ds-form-switch-ready .form-check-input {
    width: 100%;
    margin-left: 0;
    height: 2.5rem;
    margin-top: 0;
    background-color: var(--ds-color-special);
    border: 1px solid var(--ds-color-special);
}

.ds-form-switch-ready {
    min-height: 2rem;
}

.form-switch.ds-form-switch-ready .form-check-input {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg id='Gruppe_1379' data-name='Gruppe 1379' transform='translate(-185 -970)'%3E%3Ccircle id='Ellipse_604' data-name='Ellipse 604' cx='12' cy='12' r='12' transform='translate(185 970)' fill='%23004750'/%3E%3Cpath id='arrow-right-solid' d='M11.417,69.513a.834.834,0,0,0,0-1.179L7.252,64.169a.834.834,0,0,0-1.179,1.179L8.82,68.092H.833a.833.833,0,0,0,0,1.666H8.817L6.076,72.5a.834.834,0,0,0,1.179,1.179l4.165-4.165Z' transform='translate(191.169 913.075)' fill='%2300e696'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.spinner-border:before {
    content: '';
    position: absolute;
    width: 0.4rem;
    height: 0.4rem;
    margin-top: -0.2rem;
    margin-right: -0.4rem;
    right: 0%;
    top: 50%;
    background-color: var(--ds-color-primary);
    border-radius: 50%;
    outline: 0.15rem solid var(--ds-color-section-hg);
}

.spinner-border {
    border: var(--bs-spinner-border-width) solid currentcolor;
    border-color: var(--ds-color-n3);
    animation: 1.7s linear infinite var(--bs-spinner-animation-name);
}

.input-group.input-daterange input {
    text-align: center;
}

.btn.btn-primary > .spinner-border::before {
    content: '';
    position: absolute;
    width: 0.2rem;
    height: 0.2rem;
    margin-top: -0.2rem;
    margin-right: -0.2rem;
    right: 0%;
    top: 50%;
    background-color: var(--ds-color-secondary);
    border-radius: 50%;
    outline: 0.1rem solid var(--ds-color-special-active);
}

.ds-form-is-invalid {
    background-color: #f8d7da !important;
    border: 0.1em solid #dc3545;
}

.ds-form-is-valid {
    background-color: #c9f0c7 !important;
    border: 0.1em solid #0dad07;
}

.hidden { display:none; }

#ticketSubmitButton:disabled, #ticketSubmitButton:disabled:hover{
    opacity: 0.6;
    cursor: not-allowed;
    color: var(--ds-color-n3);
    pointer-events: stroke;
}
